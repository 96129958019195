import LoginService from '@/account/login.service';
import TranslationService from '@/locale/translation.service';
import Component from 'vue-class-component';
import { Inject, Vue, Watch } from 'vue-property-decorator';

@Component
export default class Topbar extends Vue {
  @Inject('translationService') private translationService: () => TranslationService;
  @Inject('loginService')
  private loginService: () => LoginService;
  // @ts-ignore
  public localFilter = this.$root.searchFilter;

  public openLogin(): void {
    this.loginService().openLogin((<any>this).$root);
  }

  public clickSearch(): void {
    // @ts-ignore
    this.$root.searchFilter = this.localFilter;
  }

  @Watch('localFilter')
  onPropertyChanged(value: string, oldValue: string) {
    // @ts-ignore
    this.$root.searchFilter = value;
  }

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  public get username(): string {
    return this.$store.getters.account?.login ?? '';
  }

  mounted() {
    this.translationService().refreshTranslation('de');
  }

  public logout(): Promise<any> {
    localStorage.removeItem('jhi-authenticationToken');
    sessionStorage.removeItem('jhi-authenticationToken');
    this.$store.commit('logout');
    if (this.$route.path !== '/login') {
      return this.$router.push('/login');
    }
    return Promise.resolve(this.$router.currentRoute);
  }
}
