












import Sidebar from '@/core/sidebar/sidebar.vue';
import Topbar from '@/core/topbar/topbar.vue';
import LoginPage from '@/account/login-page/login-page.component';

export default {
  components: { Topbar, Sidebar, LoginPage },
};
