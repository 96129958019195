var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass:
        "navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow",
    },
    [
      _c(
        "form",
        {
          staticClass:
            "d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search",
        },
        [
          _c("div", { staticClass: "input-group" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.localFilter,
                  expression: "localFilter",
                },
              ],
              staticClass: "form-control bg-light border-0 small",
              attrs: {
                type: "text",
                placeholder: "Suchen...",
                "aria-label": "Search",
                "aria-describedby": "basic-addon2",
                disabled:
                  _vm.$route.path !== "/software" &&
                  _vm.$route.path !== "/software-version" &&
                  _vm.$route.path !== "/module" &&
                  _vm.$route.path !== "/customer" &&
                  _vm.$route.path !== "/license" &&
                  _vm.$route.path !== "/activation",
              },
              domProps: { value: _vm.localFilter },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.localFilter = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "input-group-append" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  attrs: {
                    type: "button",
                    disabled:
                      _vm.$route.path !== "/software" &&
                      _vm.$route.path !== "/software-version" &&
                      _vm.$route.path !== "/module" &&
                      _vm.$route.path !== "/customer" &&
                      _vm.$route.path !== "/license" &&
                      _vm.$route.path !== "/activation",
                  },
                  on: { click: _vm.clickSearch },
                },
                [_c("i", { staticClass: "fas fa-search fa-sm" })]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("ul", { staticClass: "navbar-nav ml-auto" }, [
        _vm._m(0),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _vm._m(2),
        _vm._v(" "),
        _c("div", { staticClass: "topbar-divider d-none d-sm-block" }),
        _vm._v(" "),
        _c("li", { staticClass: "nav-item dropdown no-arrow" }, [
          _c(
            "a",
            {
              staticClass: "nav-link dropdown-toggle",
              attrs: {
                id: "userDropdown",
                role: "button",
                "data-toggle": "dropdown",
                "aria-haspopup": "true",
                "aria-expanded": "false",
              },
            },
            [
              _c(
                "span",
                { staticClass: "mr-2 d-none d-lg-inline text-gray-600 small" },
                [_vm._v(_vm._s(_vm.username))]
              ),
              _vm._v(" "),
              _c("img", {
                staticStyle: { "margin-right": "30px" },
                attrs: {
                  with: "20px",
                  src: require("../../../content/images/undraw_profile.svg"),
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "dropdown-menu dropdown-menu-right shadow animated--grow-in",
              attrs: { "aria-labelledby": "userDropdown" },
            },
            [
              _c(
                "router-link",
                {
                  staticClass: "dropdown-item",
                  attrs: { to: "/account/settings" },
                },
                [
                  _c("i", {
                    staticClass: "fas fa-user fa-sm fa-fw mr-2 text-gray-400",
                  }),
                  _vm._v("\n          Profil\n        "),
                ]
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "dropdown-item",
                  attrs: { to: "/account/password" },
                },
                [
                  _c("i", {
                    staticClass: "fas fa-cogs fa-sm fa-fw mr-2 text-gray-400",
                  }),
                  _vm._v("\n          Passwort\n        "),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "dropdown-divider" }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "dropdown-item",
                  attrs: {
                    "data-toggle": "modal",
                    "data-target": "#logoutModal",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.logout()
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass:
                      "fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400",
                  }),
                  _vm._v("\n          Logout\n        "),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "nav-item dropdown no-arrow d-sm-none" }, [
      _c(
        "a",
        {
          staticClass: "nav-link dropdown-toggle",
          attrs: {
            href: "#",
            id: "searchDropdown",
            role: "button",
            "data-toggle": "dropdown",
            "aria-haspopup": "true",
            "aria-expanded": "false",
          },
        },
        [_c("i", { staticClass: "fas fa-search fa-fw" })]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in",
          attrs: { "aria-labelledby": "searchDropdown" },
        },
        [
          _c(
            "form",
            { staticClass: "form-inline mr-auto w-100 navbar-search" },
            [
              _c("div", { staticClass: "input-group" }, [
                _c("input", {
                  staticClass: "form-control bg-light border-0 small",
                  attrs: {
                    type: "text",
                    placeholder: "Suchen...",
                    "aria-label": "Search",
                    "aria-describedby": "basic-addon2",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "input-group-append" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "button" },
                    },
                    [_c("i", { staticClass: "fas fa-search fa-sm" })]
                  ),
                ]),
              ]),
            ]
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "nav-item dropdown no-arrow mx-1" }, [
      _c(
        "a",
        {
          staticClass: "nav-link dropdown-toggle",
          attrs: {
            href: "#",
            id: "alertsDropdown",
            role: "button",
            "data-toggle": "dropdown",
            "aria-haspopup": "true",
            "aria-expanded": "false",
          },
        },
        [
          _c("i", { staticClass: "fas fa-bell fa-fw" }),
          _vm._v(" "),
          _c("span", { staticClass: "badge badge-danger badge-counter" }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in",
          attrs: { "aria-labelledby": "alertsDropdown" },
        },
        [
          _c(
            "h6",
            {
              staticClass: "dropdown-header",
              staticStyle: {
                "background-color": "#18a81f",
                "border-color": "#18a81f",
              },
            },
            [_vm._v("Alerts Center")]
          ),
          _vm._v(" "),
          _c("a", { staticClass: "dropdown-item d-flex align-items-center" }, [
            _c("div", { staticClass: "mr-3" }, [
              _c("div", { staticClass: "icon-circle bg-warning" }, [
                _c("i", {
                  staticClass: "fas fa-exclamation-triangle text-white",
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("div", { staticClass: "small text-gray-500" }),
              _vm._v("\n            No new alerts!\n          "),
            ]),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "nav-item dropdown no-arrow mx-1" }, [
      _c(
        "a",
        {
          staticClass: "nav-link dropdown-toggle",
          attrs: {
            href: "#",
            id: "messagesDropdown",
            role: "button",
            "data-toggle": "dropdown",
            "aria-haspopup": "true",
            "aria-expanded": "false",
          },
        },
        [
          _c("i", { staticClass: "fas fa-envelope fa-fw" }),
          _vm._v(" "),
          _c("span", { staticClass: "badge badge-danger badge-counter" }, [
            _vm._v("1"),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in",
          attrs: { "aria-labelledby": "messagesDropdown" },
        },
        [
          _c(
            "h6",
            {
              staticClass: "dropdown-header",
              staticStyle: {
                "background-color": "#18a81f",
                "border-color": "#18a81f",
              },
            },
            [_vm._v("Message Center")]
          ),
          _vm._v(" "),
          _c("a", { staticClass: "dropdown-item d-flex align-items-center" }, [
            _c("div", { staticClass: "dropdown-list-image mr-3" }, [
              _c("img", {
                staticClass: "rounded-circle",
                attrs: {
                  src: "https://source.unsplash.com/Mv9hjnEUHR4/60x60",
                  alt: "...",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "status-indicator bg-success" }),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("div", { staticClass: "text-truncate" }, [
                _vm._v("Welcome, admin!"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "small text-gray-500" }, [
                _vm._v("System · now"),
              ]),
            ]),
          ]),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }