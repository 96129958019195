var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      staticClass:
        "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion",
      attrs: { id: "accordionSidebar" },
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("hr", { staticClass: "sidebar-divider my-0" }),
      _vm._v(" "),
      _c(
        "li",
        { staticClass: "nav-item active" },
        [
          _c(
            "router-link",
            { staticClass: "nav-link", attrs: { to: "/home" } },
            [
              _c("i", { staticClass: "fas fa-fw fa-tachometer-alt" }),
              _vm._v(" "),
              _c("span", [_vm._v("Dashboard")]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("hr", { staticClass: "sidebar-divider" }),
      _vm._v(" "),
      _c("div", { staticClass: "sidebar-heading" }, [_vm._v("Entities")]),
      _vm._v(" "),
      _c("li", { staticClass: "nav-item" }, [
        _vm._m(1),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "collapse",
            attrs: {
              id: "collapseEntities",
              "aria-labelledby": "headingUtilities",
              "data-parent": "#accordionSidebar",
            },
          },
          [
            _c(
              "div",
              { staticClass: "bg-white py-2 collapse-inner rounded" },
              [_c("entities-menu")],
              1
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("hr", { staticClass: "sidebar-divider d-none d-md-block" }),
      _vm._v(" "),
      _vm.hasAnyAuthority("ROLE_ADMIN")
        ? _c("div", [
            _c("div", { staticClass: "sidebar-heading" }, [_vm._v("Admin")]),
            _vm._v(" "),
            _c("li", { staticClass: "nav-item" }, [
              _vm._m(2),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "collapse",
                  attrs: {
                    id: "collapseUtilities",
                    "aria-labelledby": "headingUtilities",
                    "data-parent": "#accordionSidebar",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "bg-white py-2 collapse-inner rounded" },
                    [
                      _c("h6", { staticClass: "collapse-header" }, [
                        _vm._v("Administration"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "collapse-item",
                          attrs: { to: "/admin/user-management" },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "users-cog" },
                          }),
                          _vm._v("Benutzerverwaltung"),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "collapse-item",
                          attrs: { to: "/admin/metrics" },
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "users" } }),
                          _vm._v(" Metriken"),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "collapse-item",
                          attrs: { to: "/admin/health" },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "tachometer-alt" },
                          }),
                          _vm._v("\n            Status"),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "collapse-item",
                          attrs: { to: "/admin/configuration" },
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "heart" } }),
                          _vm._v("\n            Konfiguration"),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "collapse-item",
                          attrs: { to: "/admin/logs" },
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "cogs" } }),
                          _vm._v("\n            Protokoll"),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "collapse-item",
                          attrs: { to: "/admin/docs" },
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "tasks" } }),
                          _vm._v("\n            API"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass:
          "sidebar-brand d-flex align-items-center justify-content-center",
      },
      [
        _c("div", { staticClass: "sidebar-brand-icon" }, [
          _c("img", {
            staticClass: "img-fluid",
            attrs: {
              id: "main-logo",
              src: require("../../../content/images/sidebar_logo.svg"),
              width: "150px",
              alt: "LiSense",
            },
          }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "nav-link collapsed",
        attrs: {
          href: "#",
          "data-toggle": "collapse",
          "data-target": "#collapseEntities",
          "aria-expanded": "true",
          "aria-controls": "collapseEntities",
        },
      },
      [
        _c("i", { staticClass: "fas fa-fw fa-table" }),
        _vm._v(" "),
        _c("span", [_vm._v("Entities")]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "nav-link collapsed",
        attrs: {
          href: "#",
          "data-toggle": "collapse",
          "data-target": "#collapseUtilities",
          "aria-expanded": "true",
          "aria-controls": "collapseUtilities",
        },
      },
      [
        _c("i", { staticClass: "fas fa-fw fa-wrench" }),
        _vm._v(" "),
        _c("span", [_vm._v("Administration")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }