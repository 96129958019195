import $ from '../../../content/theme/vendor/jquery/jquery';
import Component from 'vue-class-component';
import { Inject, Vue } from 'vue-property-decorator';
import AccountService from '@/account/account.service';
import EntitiesMenu from '@/entities/entities-menu.vue';

@Component({
  components: {
    'entities-menu': EntitiesMenu,
  },
})
export default class Sidebar extends Vue {
  @Inject('accountService') private accountService: () => AccountService;
  private hasAnyAuthorityValues = {};
  private hasAuthority = false;

  public collapseSidebar(): void {
    if ($('#main-logo').width() == 80) {
      $('#main-logo').width(150);
    } else {
      $('#main-logo').width(80);
    }
    $('body').toggleClass('sidebar-toggled');
    $('.sidebar').toggleClass('toggled');
    if ($('.sidebar').hasClass('toggled')) {
      $('.sidebar .collapse').collapse('hide');
    }
  }

  public logout(): Promise<any> {
    localStorage.removeItem('jhi-authenticationToken');
    sessionStorage.removeItem('jhi-authenticationToken');
    this.$store.commit('logout');
    if (this.$route.path !== '/') {
      return this.$router.push('/');
    }
    return Promise.resolve(this.$router.currentRoute);
  }

  public hasAnyAuthority(authorities: any): boolean {
    this.accountService()
      .hasAnyAuthorityAndCheckAuth(authorities)
      .then(value => {
        this.hasAuthority = value;
        this.hasAnyAuthorityValues[authorities] = value;
      });
    return this.hasAuthority;
  }

  public get authenticated(): boolean {
    console.log(this.$store.getters.authenticated);
    return this.$store.getters.authenticated;
  }
}
