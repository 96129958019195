import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

// prettier-ignore
const Software = () => import('@/entities/software/software.vue');
// prettier-ignore
const SoftwareUpdate = () => import('@/entities/software/software-update.vue');
// prettier-ignore
const SoftwareDetails = () => import('@/entities/software/software-details.vue');
// prettier-ignore
const SoftwareVersion = () => import('@/entities/software-version/software-version.vue');
// prettier-ignore
const SoftwareVersionUpdate = () => import('@/entities/software-version/software-version-update.vue');
// prettier-ignore
const SoftwareVersionDetails = () => import('@/entities/software-version/software-version-details.vue');
// prettier-ignore
const Module = () => import('@/entities/module/module.vue');
// prettier-ignore
const ModuleUpdate = () => import('@/entities/module/module-update.vue');
// prettier-ignore
const ModuleDetails = () => import('@/entities/module/module-details.vue');
// prettier-ignore
const Customer = () => import('@/entities/customer/customer.vue');
// prettier-ignore
const CustomerUpdate = () => import('@/entities/customer/customer-update.vue');
// prettier-ignore
const CustomerDetails = () => import('@/entities/customer/customer-details.vue');
// prettier-ignore
const License = () => import('@/entities/license/license.vue');
// prettier-ignore
const LicenseUpdate = () => import('@/entities/license/license-update.vue');
// prettier-ignore
const LicenseDetails = () => import('@/entities/license/license-details.vue');
// prettier-ignore
const Activation = () => import('@/entities/activation/activation.vue');
// prettier-ignore
const ActivationUpdate = () => import('@/entities/activation/activation-update.vue');
// prettier-ignore
const ActivationDetails = () => import('@/entities/activation/activation-details.vue');
// prettier-ignore
const ActivationLog = () => import('@/entities/activation-log/activation-log.vue');
// prettier-ignore
const ActivationLogUpdate = () => import('@/entities/activation-log/activation-log-update.vue');
// prettier-ignore
const ActivationLogDetails = () => import('@/entities/activation-log/activation-log-details.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here
const Topbar = () => import('@/core/topbar/topbar.vue');
const Sidebar = () => import('@/core/sidebar/sidebar.vue');

export default {
  path: '/',
  components: { default: Entities, Topbar, Sidebar },
  children: [
    {
      path: 'software',
      name: 'Software',
      component: Software,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'software/new',
      name: 'SoftwareCreate',
      component: SoftwareUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'software/:softwareId/edit',
      name: 'SoftwareEdit',
      component: SoftwareUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'software/:softwareId/view',
      name: 'SoftwareView',
      component: SoftwareDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'software-version',
      name: 'SoftwareVersion',
      component: SoftwareVersion,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'software/:softwareId/software-version/new',
      name: 'SoftwareVersionCreate',
      component: SoftwareVersionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'software/:softwareId/software-version/:softwareVersionId/edit',
      name: 'SoftwareVersionEdit',
      component: SoftwareVersionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'software/:softwareId/software-version/:softwareVersionId/view',
      name: 'SoftwareVersionView',
      component: SoftwareVersionDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'module',
      name: 'Module',
      component: Module,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'software/:softwareId/module/new',
      name: 'ModuleCreate',
      component: ModuleUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'software/:softwareId/module/:moduleId/edit',
      name: 'ModuleEdit',
      component: ModuleUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'software/:softwareId/module/:moduleId/view',
      name: 'ModuleView',
      component: ModuleDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer',
      name: 'Customer',
      component: Customer,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer/new',
      name: 'CustomerCreate',
      component: CustomerUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer/:customerId/edit',
      name: 'CustomerEdit',
      component: CustomerUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer/:customerId/view',
      name: 'CustomerView',
      component: CustomerDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'license',
      name: 'License',
      component: License,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'license/new',
      name: 'LicenseCreate',
      component: LicenseUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'license/:licenseId/edit',
      name: 'LicenseEdit',
      component: LicenseUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'license/:licenseId/view',
      name: 'LicenseView',
      component: LicenseDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'activation',
      name: 'Activation',
      component: Activation,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'activation/new',
      name: 'ActivationCreate',
      component: ActivationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'activation/:activationId/edit',
      name: 'ActivationEdit',
      component: ActivationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'activation/:activationId/view',
      name: 'ActivationView',
      component: ActivationDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/activation',
      name: 'Activation',
      component: Activation,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/activation/new',
      name: 'ActivationCreate',
      component: ActivationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/activation/:activationId/edit',
      name: 'ActivationEdit',
      component: ActivationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/activation/:activationId/view',
      name: 'ActivationView',
      component: ActivationDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/customer',
      name: 'Customer',
      component: Customer,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/customer/new',
      name: 'CustomerCreate',
      component: CustomerUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/customer/:customerId/edit',
      name: 'CustomerEdit',
      component: CustomerUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/customer/:customerId/view',
      name: 'CustomerView',
      component: CustomerDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/activation-log',
      name: 'ActivationLog',
      component: ActivationLog,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/activation-log/new',
      name: 'ActivationLogCreate',
      component: ActivationLogUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/activation-log/:activationLogId/edit',
      name: 'ActivationLogEdit',
      component: ActivationLogUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: '/activation-log/:activationLogId/view',
      name: 'ActivationLogView',
      component: ActivationLogDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
