import { Authority } from '@/shared/security/authority';

const JhiUserManagementComponent = () => import('@/admin/user-management/user-management.vue');
const JhiUserManagementViewComponent = () => import('@/admin/user-management/user-management-view.vue');
const JhiUserManagementEditComponent = () => import('@/admin/user-management/user-management-edit.vue');
const JhiDocsComponent = () => import('@/admin/docs/docs.vue');
const JhiConfigurationComponent = () => import('@/admin/configuration/configuration.vue');
const JhiHealthComponent = () => import('@/admin/health/health.vue');
const JhiLogsComponent = () => import('@/admin/logs/logs.vue');
const JhiMetricsComponent = () => import('@/admin/metrics/metrics.vue');
const Topbar = () => import('@/core/topbar/topbar.vue');
const Sidebar = () => import('@/core/sidebar/sidebar.vue');

export default [
  {
    path: '/admin/user-management',
    name: 'JhiUser',
    components: { default: JhiUserManagementComponent, Topbar, Sidebar },
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/user-management/new',
    name: 'JhiUserCreate',
    components: { default: JhiUserManagementEditComponent, Topbar, Sidebar },
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/user-management/:userId/edit',
    name: 'JhiUserEdit',
    components: { default: JhiUserManagementEditComponent, Topbar, Sidebar },
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/user-management/:userId/view',
    name: 'JhiUserView',
    components: { default: JhiUserManagementViewComponent, Topbar, Sidebar },
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/docs',
    name: 'JhiDocsComponent',
    components: { default: JhiDocsComponent, Topbar, Sidebar },
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/health',
    name: 'JhiHealthComponent',
    components: { default: JhiHealthComponent, Topbar, Sidebar },
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/logs',
    name: 'JhiLogsComponent',
    components: { default: JhiLogsComponent, Topbar, Sidebar },
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/metrics',
    name: 'JhiMetricsComponent',
    components: { default: JhiMetricsComponent, Topbar, Sidebar },
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/configuration',
    name: 'JhiConfigurationComponent',
    components: { default: JhiConfigurationComponent, Topbar, Sidebar },
    meta: { authorities: [Authority.ADMIN] },
  },
];
