import { Inject, Vue } from 'vue-property-decorator';
import AccountService from '@/account/account.service';
import axios from 'axios';
import Component from 'vue-class-component';
import TranslationService from '@/locale/translation.service';

@Component
export default class LoginPage extends Vue {
  @Inject('translationService')
  private translationService: () => TranslationService;
  @Inject('accountService')
  private accountService: () => AccountService;
  public authenticationError = false;
  public login = null;
  public password = null;
  public rememberMe = false;
  public tryAuthenticationError = false;

  public doLogin(): void {
    const data = { username: this.login, password: this.password, rememberMe: this.rememberMe };
    axios
      .post('api/authenticate', data)
      .then(result => {
        const bearerToken = result.headers.authorization;
        if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
          const jwt = bearerToken.slice(7, bearerToken.length);
          if (this.rememberMe) {
            localStorage.setItem('jhi-authenticationToken', jwt);
            sessionStorage.removeItem('jhi-authenticationToken');
          } else {
            sessionStorage.setItem('jhi-authenticationToken', jwt);
            localStorage.removeItem('jhi-authenticationToken');
          }
        }
        this.authenticationError = false;
        this.accountService().retrieveAccount();
      })
      .catch(() => {
        console.log('Authenticaction Error');
        this.authenticationError = true;
      });
  }

  public wrapperDoLogin(): void {
    this.doLogin();
    if (this.authenticationError == false) {
      this.$router.push({ name: 'Home' }).catch(() => {});
    }
  }

  mounted() {
    this.translationService().refreshTranslation('de');
    this.$router.push({ name: 'Home' }).catch(() => {});
  }
}
