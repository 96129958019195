var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "app" } }, [
    _c(
      "div",
      { attrs: { id: "wrapper" } },
      [
        _c("router-view", { attrs: { name: "Sidebar" } }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "d-flex flex-column",
            attrs: { id: "content-wrapper" },
          },
          [
            _c("router-view", { attrs: { name: "Topbar" } }),
            _vm._v(" "),
            _c("router-view"),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }